import https from "./https"

const tokenApi = {
  getTokens (organization, params) {
    const url = 'admin/organizations/' + organization.id  + '/tokens'

    return https.get(url, { params })
  },
  getToken (token_id) {
    const url = 'admin/tokens/' + token_id
    return https.get(url)
  },
  updateToken (token_id, params) {
    const url = 'admin/tokens/' + token_id
    return https.put(url, params)
  },
  removeToken (token_id) {
    const url = 'admin/tokens/' + token_id
    return https.delete(url)
  },
  createToken (organization, params) {
    const url = 'admin/organizations/' + organization.id  + '/tokens'
    return https.post(url, params)
  },
}

export default tokenApi
